<troi-modal-header [askBeforeClose]="true" (modalWantClose)="wantCloseModal()">
  <span>{{ 'Booking.labels.bookingReceiptForm' | translate }}</span>
</troi-modal-header>
<troi-modal-content style="overflow-y: auto">
  <form class="booking-form" [formGroup]="bookingModel.formValues">
    <div class="content">
      <div class="content__left">
        <troi-modal-section
          label="{{ 'Booking.labels.baseData' | translate }}"
          [forceOpen]="shouldSectionBeOpened(0)"
          [showCheckmark]="true"
          [checkmarkChecked]="bookingModel.states.baseDataFinalized"
          (stateChanged)="sectionStateChanged($event)"
          [sectionId]="0"
        >
          <troi-modal-row>
            <div row-label>{{ 'Common.labels.supplier' | translate }}</div>
            <div row-value class="booking-form__row">
              <div class="booking-form__supplier-row">
                <troi-dropdown-select-lazy
                  [filterObject]="modalObject.client"
                  [year]="modalObject.year"
                  dataType="SUPPLIER"
                  [initValue]="bookingModel.getFormValueIdForKey('supplier')"
                  [defaultEmptyValue]="null"
                  [reloadInitData]="reloadSupplierData"
                  (selected)="supplierSelected($event?.fullObject)"
                  [withSearch]="true"
                  [returnSelectedObject]="true"
                  [enabled]="canWriteBaseData()"
                ></troi-dropdown-select-lazy>
                <troi-icon
                  icon="icon-plus"
                  (click)="openCreateSupplierWindow()"
                  *ngIf="canCreateSupplier() && canWriteBaseData()"
                  title="{{ 'Booking.labels.createNewSupplier' | translate }}"
                ></troi-icon>
                <troi-icon
                  [disabled]="true"
                  icon="icon-plus"
                  title="{{ 'Booking.labels.noRightsToCreateSupplier' | translate }}"
                  *ngIf="!canCreateSupplier()"
                ></troi-icon>
              </div>

              <div
                class="booking-form__supplier-no-account"
                *ngIf="bookingModel.supplierHasNotCreditAccount && canWriteBaseData()"
              >
                <span>{{ 'Booking.labels.supplierNoCreditAccount' | translate }}</span>
                <div class="button">
                  <troi-btn [outline]="true" [submit]="false" (click)="openUpdateSupplierWindow()">
                    {{ 'Booking.labels.connectAccount' | translate }}
                  </troi-btn>
                </div>
              </div>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>
              {{ 'Booking.labels.receiptNumber' | translate }}
              <span *ngIf="!bookingSettingsService.settings.settings.bookingReceiptInvoiceNumberIsOptional">*</span>
            </div>
            <div row-value class="booking-form__row h-40">
              <troi-input-field
                [fieldInvalid]="!isBookingNumberCorrect() && formService.submitted"
                [validationEnabled]="!bookingSettingsService.settings.settings.bookingReceiptInvoiceNumberIsOptional"
                row-value
                [enabled]="canWriteBaseData()"
                formControlName="number"
                [requiredFieldErrorMessage]="'Booking.error.receiptNumberIsRequired'"
              ></troi-input-field>
            </div>
            <div
              row-value
              class="booking-form__row m-l-10 h-40"
              *ngIf="bookingSettingsService.settings.settings.additionalBookingNumber"
            >
              <troi-input-field
                row-value
                [enabled]="canWriteBaseData()"
                formControlName="additionalNumber"
              ></troi-input-field>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.intDocumentNumber' | translate }}</div>
            <div row-value class="booking-form__row h-40">
              <troi-input-field [enabled]="false" [value]="bookingModel.internalNumber"></troi-input-field>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.invoiceAmount' | translate }} *</div>
            <div row-value class="booking-form__row" *ngIf="showQuantityData()">
              <troi-input-field
                class="booking-form__value booking-form__value--small"
                [numbersOnly]="true"
                [enabled]="canWriteBaseData()"
                (change)="updateQuantity($event.target.value)"
                [value]="getFormattedNumber(bookingModel.amount.quantity)"
                [placeholder]="generateAmountPlaceholder()"
                row-value
                [requiredFieldErrorMessage]="'Booking.error.amountIsRequired'"
              ></troi-input-field>
            </div>
            <div row-value class="booking-form__row m-l-5 units" *ngIf="showQuantityData()">
              <troi-dropdown-select-lazy
                class="booking-form__units"
                [filterObject]="modalObject.client"
                [initValue]="bookingModel.amount.unit"
                dataType="UNITS"
                [enabled]="canWriteBaseData()"
                [clearButton]="false"
                (selected)="bookingModel.amount.unit = $event"
                [withSearch]="false"
                [translatableName]="true"
                [initLazyOptionOnInit]="true"
              ></troi-dropdown-select-lazy>
            </div>
            <div row-value class="booking-form__row amount-label" style="width: auto" *ngIf="showQuantityData()">x</div>
            <div row-value class="booking-form__row m-l-5" *ngIf="showQuantityData()">
              <troi-input-field
                class="add-edit__row__inline__input booking-form__value booking-form__value--small"
                [value]="bookingModel.amount.pricePerUnit?.formattedValue"
                (change)="updatePricePerUnit($event.target.value)"
                money-format
                [money]="bookingModel.amount.pricePerUnit"
                [numbersOnly]="true"
                [enabled]="canWriteBaseData()"
                [currency]="bookingSettingsService.systemCurrency"
              ></troi-input-field>
            </div>
            <div row-value class="booking-form__row amount-label" style="width: auto" *ngIf="showQuantityData()">=</div>
            <div row-value class="booking-form__row h-40">
              <troi-input-field
                class="add-edit__row__inline__input booking-form__value"
                [ngClass]="{
                  'booking-form__value--small': showQuantityData()
                }"
                [fieldInvalid]="
                  bookingModel.amount.totalPrice.isZero() && formService.submitted && !bookingModel.amount.isQuantified
                "
                [validationEnabled]="true"
                [enabled]="!showQuantityData() && canWriteBaseData()"
                [value]="bookingModel.amount.totalPrice?.formattedValue"
                (change)="totalAmountChanged($event.target.value)"
                money-format
                [money]="bookingModel.amount.totalPrice"
                [numbersOnly]="true"
                [currency]="bookingSettingsService.systemCurrency"
                [requiredFieldErrorMessage]="'Booking.error.amountIsRequired'"
              ></troi-input-field>
            </div>
            <div
              row-value
              class="booking-form__row m-l-5 icon-quantity"
              [ngClass]="{ quantityDisabled: !canWriteBaseData() }"
              (click)="refreshTotalPrice(); bookingModel.amount.isQuantified = !bookingModel.amount.isQuantified"
              *ngIf="bookingSettingsService.settings.settings.allowQuantity"
            >
              <troi-icon
                icon="icon-calc quantity"
                size="20px"
                title="{{ 'Booking.labels.showQuantityToolTip' | translate }}"
              ></troi-icon>
            </div>
            <div row-value class="booking-form__row m-l-5 tax-options">
              <troi-checkbox
                data-name="N"
                label="{{ 'Booking.labels.amountInNet' | translate }}"
                title="{{ 'Booking.labels.bookingFormNetLabel' | translate }}"
                [value]="bookingModel.amount.isNet"
                [disabled]="!canWriteBaseData()"
                [ngClass]="{ disabled: !canWriteBaseData() }"
                (checkboxChange)="bookingModel.amount.isNet = $event; updateNetAmount()"
                class="label"
              ></troi-checkbox>
              <troi-checkbox
                data-name="GR"
                label="{{ 'Booking.labels.amountInGross' | translate }}"
                title="{{ 'Booking.labels.bookingFormGrossLabel' | translate }}"
                [value]="!bookingModel.amount.isNet"
                [disabled]="!canWriteBaseData()"
                [ngClass]="{ disabled: !canWriteBaseData() }"
                (checkboxChange)="bookingModel.amount.isNet = !$event; updateNetAmount()"
                class="label"
                [removeLeftBorder]="true"
              ></troi-checkbox>
            </div>
            <div row-value class="booking-form__row m-l-5 tax">
              <troi-dropdown-select-lazy
                [fieldInvalid]="isBaseDataTaxInvalid()"
                [validationEnabled]="true"
                [filterObject]="modalObject.client"
                dataType="TAX_RATES"
                [initValue]="bookingModel.amount.tax?.id"
                [translatableName]="true"
                [translationMode]="translationMode.NAVIGATION"
                [defaultEmptyValue]="null"
                [predefinedOptions]="generateMultipleTaxOption()"
                (selected)="onBaseDataTaxChange($event?.fullObject)"
                [withSearch]="false"
                [initLazyOptionOnInit]="true"
                [placeholder]="generateTaxPlaceholder()"
                [validationMessage]="generateTaxValidationMessage()"
                [returnSelectedObject]="true"
                [enabled]="canWriteBaseData()"
                [pageSize]="50"
              ></troi-dropdown-select-lazy>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.receiptDate' | translate }} *</div>
            <div row-value class="booking-form__row h-40">
              <troi-range-date
                [fieldInvalid]="bookingModel.formValues.controls.bookingDate.errors && formService.submitted"
                [validationEnabled]="true"
                [rangeFrom]="bookingModel.getFormValueIdForKey('bookingDate')"
                [rangeTo]="null"
                [singleDatePicker]="true"
                [emitIfEmpty]="true"
                [disabled]="!canWriteBaseData()"
                (rangeChanged)="updateBookingDate($event)"
                [requiredFieldErrorMessage]="'Booking.error.receiptDateIsRequired'"
              ></troi-range-date>
            </div>
            <div
              row-value
              class="booking-form__row m-l-10"
              style="width: 50%"
              *ngIf="bookingSettingsService.settings.settings.paymentTerms"
            >
              <payment-terms
                [numberOfCashDiscounts]="getNumberOfCashDiscounts()"
                [enabled]="canWriteBaseData()"
                [terms]="paymentTerms"
                (termsSaved)="termsChanged($event)"
              ></payment-terms>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.postingText' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-input-field
                [enabled]="canWriteBaseData()"
                row-value
                formControlName="postingText"
              ></troi-input-field>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.paymentMethod' | translate }}</div>
            <div row-value class="booking-form__row paid-by">
              <div class="paid-by__select">
                <troi-dropdown-select
                  [enabled]="canWriteBaseData()"
                  formControlName="paymentMethod"
                  [size]="'11px'"
                  placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
                  class="troi-dropdown-select--full"
                  [doubleHeight]="true"
                  [clearButton]="false"
                  [options]="paymentMethodsList"
                ></troi-dropdown-select>
              </div>
              <div class="paid-by__employee">
                <span>{{ 'Booking.labels.paidByEmployee' | translate }}</span>
                <troi-switch
                  [ngClass]="{ disabled: !canWriteBaseData() }"
                  [clickable]="canWriteBaseData()"
                  row-value
                  formControlName="paidByEmployee"
                  (click)="togglePaidByEmployee()"
                  small
                ></troi-switch>
                <troi-dropdown-select-lazy
                  *ngIf="bookingModel.getFormValueIdForKey('paidByEmployee')"
                  [enabled]="canWriteBaseData()"
                  [validationEnabled]="bookingModel.getFormValueIdForKey('paidByEmployee')"
                  [fieldInvalid]="!isPaidByEmployeeValid() && formService.submitted"
                  [filterObject]="modalObject.client"
                  dataType="EMPLOYEES"
                  [initValue]="bookingModel.getFormValueIdForKey('paidBy')"
                  (selected)="paidByChanged($event)"
                  [defaultEmptyValue]="null"
                  [withSearch]="true"
                  [predefinedOptions]="initUserOption()"
                  placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
                ></troi-dropdown-select-lazy>
              </div>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.provisionDate' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-range-date
                [validationEnabled]="true"
                [rangeFrom]="bookingModel.getFormValueIdForKey('provisionDate')"
                [rangeTo]="null"
                [singleDatePicker]="true"
                [emitIfEmpty]="true"
                [disabled]="!canWriteBaseData()"
                (rangeChanged)="updateProvisionDate($event)"
              ></troi-range-date>
            </div>
          </troi-modal-row>
        </troi-modal-section>
        <troi-modal-section
          label="{{ 'Booking.labels.projectAssignment' | translate }}"
          [forceOpen]="shouldSectionBeOpened(1)"
          [showCheckmark]="true"
          [checkmarkChecked]="bookingModel.states.projectAssignmentFinalized"
          (stateChanged)="sectionStateChanged($event)"
          [sectionId]="1"
        >
          <troi-modal-row>
            <div row-label>{{ 'Common.labels.client' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-dropdown-select
                formControlName="client"
                [size]="'11px'"
                class="troi-dropdown-select--full"
                [initValue]="modalObject?.client"
                [enabled]="false"
                [options]="basicFilters.generateClientsDropdown()"
              ></troi-dropdown-select>
            </div>
          </troi-modal-row>
          <troi-modal-row>
            <div row-label>{{ 'Booking.labels.multipleCP' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-switch
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="bookingModel.multipleCps"
                (click)="onMultipleCpCheckboxChange()"
                [clickable]="false"
                [ngClass]="{ disabled: !canWriteProjectAssignment() }"
                small
              ></troi-switch>
            </div>
          </troi-modal-row>
          <troi-multiple-add-project
            *ngIf="bookingModel.isMultipleCps()"
            [enabled]="canWriteProjectAssignment()"
            [client]="modalObject?.client"
            [bookingModel]="bookingModel"
            (taxChanged)="onTaxInMultipleCpChanged()"
          >
          </troi-multiple-add-project>
          <troi-modal-row *ngIf="projectAssignmentDataLoading">
            <div row-label></div>
            <div row-value class="booking-form__row">
              <troi-loading-spinner width="500px" height="50px"></troi-loading-spinner>
            </div>
          </troi-modal-row>
          <troi-modal-row *ngIf="showSinglePADropdowns()">
            <div row-label>{{ 'Common.labels.customer' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-dropdown-select-lazy
                [filterObject]="modalObject.client"
                dataType="CUSTOMERS"
                [initValue]="getFormValueForKey('customer')?.id"
                (selected)="onCustomerChange($event.fullObject)"
                [predefinedOptions]="generateDropdownOption(getFormValueForKey('customer'))"
                [withSearch]="true"
                [returnSelectedObject]="true"
                [enabled]="canWriteProjectAssignment()"
                [clearButton]="false"
              >
              </troi-dropdown-select-lazy>
            </div>
          </troi-modal-row>
          <troi-modal-row *ngIf="showSinglePADropdowns()">
            <div row-label>{{ 'Common.labels.project' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-dropdown-select-lazy
                [filterObject]="getIdFromDropdown('customer')"
                dataType="PROJECTS_BY_CUSTOMER"
                [translatableName]="true"
                [initValue]="getIdFromDropdown('project')"
                [reloadInitData]="getIdFromDropdown('customer')"
                (selected)="onProjectChange($event.fullObject)"
                [predefinedOptions]="generateDropdownOption(getFormValueForKey('project'))"
                [withSearch]="true"
                [lang]="languagesService.getLanguage()"
                [enabled]="!hasSelectedAutoProject() && canWriteProjectAssignment()"
                [returnSelectedObject]="true"
                [clearButton]="false"
              >
              </troi-dropdown-select-lazy>
            </div>
          </troi-modal-row>
          <troi-modal-row *ngIf="showSinglePADropdowns()">
            <div row-label>{{ 'Common.labels.subproject' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-dropdown-select-lazy
                [filterObject]="getIdFromDropdown('project')"
                dataType="SUB_PROJECTS"
                [translatableName]="true"
                [initValue]="getIdFromDropdown('subproject')"
                (selected)="onSubprojectChange($event.fullObject)"
                [reloadInitData]="shouldReloadSubProjects()"
                [predefinedOptions]="generateDropdownOption(getFormValueForKey('subproject'))"
                [withSearch]="true"
                [clearButton]="false"
                [lang]="languagesService.getLanguage()"
                [enabled]="
                  getIdFromDropdown('project') !== null && !hasSelectedAutoSubproject() && canWriteProjectAssignment()
                "
                [returnSelectedObject]="true"
                [colorizeBackgroundProperty]="'hasNotAnyCalculationPositionWithExternalLabour'"
              >
              </troi-dropdown-select-lazy>
            </div>
          </troi-modal-row>
          <troi-modal-row *ngIf="showSinglePADropdowns()">
            <div row-label>{{ 'Common.labels.cp' | translate }}</div>
            <div row-value class="booking-form__row cps">
              <div class="cps__description">
                <span class="cps__description__cp">{{ 'Common.labels.cp' | translate }}</span>
                <span class="cps__description__tax">{{ 'Booking.labels.tax' | translate }}</span>
                <span class="cps__description__amount">{{ 'Booking.labels.amount' | translate }} (net)</span>
              </div>
              <div class="cps__values">
                <troi-cp-data
                  class="cp__data__dropdown"
                  [enabled]="
                    getIdFromDropdown('subproject') !== null &&
                    !hasSelectedAutoSubproject() &&
                    canWriteProjectAssignment()
                  "
                  [filterObject]="getIdFromDropdown('subproject')"
                  [initValue]="getIdFromDropdown('cp')"
                  [preloadedOptions]="generateDropdownOption(getFormValueForKey('cp'))"
                  [reloadInitData]="getIdFromDropdown('subproject')"
                  [secondFilterObject]="modalObject?.client"
                  (cpChanged)="onCpChanged($event)"
                ></troi-cp-data>
                <troi-dropdown-select-lazy
                  [filterObject]="modalObject?.client"
                  dataType="TAX_RATES"
                  [translatableName]="true"
                  [enabled]="false"
                  class="cps__values__tax"
                  [initValue]="bookingModel.amount.getTaxId()"
                  [initLazyOptionOnInit]="true"
                  placeholder="Booking.labels.0none"
                  [pageSize]="50"
                ></troi-dropdown-select-lazy>
                <troi-input-field
                  class="add-edit__row__inline__input"
                  [enabled]="false"
                  [value]="bookingModel.amount.totalNetAmount?.formattedValue"
                  money-format
                  [money]="bookingModel.amount.totalNetAmount"
                  [currency]="bookingSettingsService.systemCurrency"
                ></troi-input-field>
              </div>
            </div>
          </troi-modal-row>
          <troi-modal-row *ngIf="!bookingModel.isMultipleCps()" style="margin-top: 20px">
            <div row-label>{{ 'Booking.labels.addProject' | translate }}</div>
            <div row-value class="booking-form__row">
              <troi-add-project
                [client]="modalObject?.client"
                (dataSelected)="onProjectAssignmentSelected($event)"
                [enabled]="canWriteProjectAssignment()"
                [multipleCps]="bookingModel.multipleCps"
              ></troi-add-project>
            </div>
          </troi-modal-row>
        </troi-modal-section>
        <troi-modal-section
          label="{{ 'Booking.labels.accounting' | translate }}"
          class="last-section"
          [forceOpen]="shouldSectionBeOpened(2)"
          *ngIf="bookingSettingsService.settings.settings.bookkeepingAccountAssignment"
          [showCheckmark]="true"
          [checkmarkChecked]="bookingModel.states.accountingFinalized"
          (stateChanged)="sectionStateChanged($event)"
          [sectionId]="2"
        >
          <troi-accounting-list-header></troi-accounting-list-header>
          <troi-accounting-list
            [bookingModel]="bookingModel"
            [amount]="bookingModel?.amount.totalNetAmount"
            (recalculateAmount)="updateNetAmount()"
            [tax]="bookingModel?.amount.tax"
            [year]="modalObject.year"
            [supplier]="bookingModel.formValues.get('supplier').value"
            [enabled]="canWriteAccounting()"
          ></troi-accounting-list>
          <troi-accounting-list-total
            [total]="bookingModel.accountingTotal"
            [enabled]="canWriteAccounting()"
            [showPaid]="bookingSettingsService.settings.settings.showIsPaid"
            [paid]="bookingModel.isPaid()"
            (paidChanged)="isPaidChanged($event)"
          ></troi-accounting-list-total>
        </troi-modal-section>
      </div>
      <div class="content__right">
        <booking-files
          [client]="modalObject.client"
          [year]="modalObject.year"
          [assignedFile]="modalObject.filesToAssign"
          (filesAssignmentChanged)="bookingModel.files = $event"
          [bookingModel]="bookingModel"
          [enabled]="canWriteBaseData()"
          [isDigitalInvoice]="modalObject.isDigitalInvoice"
          [digitalInvoiceType]="modalObject.digitalInvoiceType"
        ></booking-files>
      </div>
    </div>
  </form>
</troi-modal-content>
<troi-modal-footer class="booking-form__modal-footer">
  <div footer-options>
    <div *ngIf="bookingModel.id">
      <approval-status-bar
        *ngIf="bookingSettingsService.settings?.approval?.booking?.isEnabled"
        [id]="bookingModel.id"
        (change)="onChangeApprovalStatus()"
      ></approval-status-bar>
    </div>
  </div>
  <div class="fill-required" *ngIf="formService.submitted && !baseDataFilledCorrectly()">
    {{ 'Booking.labels.fillRequiredFields' | translate }}
  </div>
  <troi-btn (click)="wantCloseModal()" class="add-edit__button" [outline]="true">
    {{ 'Booking.labels.discard' | translate }}
  </troi-btn>
  <troi-btn class="add-edit__button m-l-10" [disabled]="shouldDisableSaveButton()" [outline]="true" (click)="save()">
    {{ 'Booking.labels.save' | translate }}
  </troi-btn>
  <troi-btn class="add-edit__button m-l-10" [disabled]="shouldDisableSaveButton()" (click)="save(true)">
    {{ 'Booking.labels.saveAndClose' | translate }}
  </troi-btn>
</troi-modal-footer>
