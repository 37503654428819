import { Injectable } from '@angular/core';
import { BackendResponseInterface } from '../../../../../../core/interfaces/backend.response.interface';
import { LanguagesService } from '../../../../../../core/services/languages.service';
import { TreeListElementStatus } from '../../../../../common/enums/tree-list-element-status';
import { InvoiceAssignNumberModel } from '../../../../models/invoice-assign-number.model';
import { ProjectListNetwork } from '../../../../network/project-list.network';

@Injectable()
export class InvoiceAssignNumbersService {
  public showAll = false;
  public showOptional = false;
  public isLoading = true;
  public items: InvoiceAssignNumberModel[];

  constructor(private projectListNetwork: ProjectListNetwork, private languageService: LanguagesService) {}

  public toggleShowAll(projectId: string | number, value: boolean): void {
    this.showAll = value;
    this.loadSubProjectList(projectId);
  }

  public toggleShowOptional(projectId: string | number, value: boolean): void {
    this.showOptional = value;
    this.loadSubProjectList(projectId);
  }

  public loadSubProjectList(projectId: string | number): void {
    this.isLoading = true;
    this.projectListNetwork
      .fetchInvoiceAssignNumbers(
        projectId,
        { lang: this.languageService.getDataLanguage(), showAll: this.showAll, showOptional: this.showOptional },
        this.showAll,
      )
      .subscribe((result: BackendResponseInterface<InvoiceAssignNumberModel[]>) => {
        this.items = [
          ...result.data.map((item: InvoiceAssignNumberModel) => ({
            ...item,
            treeStatus: item.parent ? item.treeStatus : TreeListElementStatus.EXPANDED,
          })),
        ];
        this.isLoading = false;
      });
  }
}
