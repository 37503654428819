import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseStatusInterface } from '../../../../core/interfaces/backend.response.interface';
import { timePresentation } from '../../shared/time-switch/timePresentation';
import Schedule from '../stopwatch/schedule';

@Injectable()
export class WorkingTimeService {
  private readonly apiEndpointWorktimes: string = '/components/desktop/work-timerecording';
  private readonly apiEndpointCheckin: string = '/components/desktop/work-timerecording/checkin';
  private readonly apiEndpointCheckout: string = '/components/desktop/work-timerecording/checkout';
  private readonly apiEndpointStartPause: string = '/components/desktop/work-timerecording/start-pause';
  private readonly apiEndpointStopPause: string = '/components/desktop/work-timerecording/stop-pause';

  private queryDate: any;
  private subject = new Subject<any>();

  private checkedInSubject = new Subject<any>();
  private pauseSubject = new Subject<any>();

  public readonly WORKLOG_STATE_NEW: string = 'new';
  public readonly WORKLOG_STATE_RUNNING: string = 'running';
  public readonly WORKLOG_STATE_PAUSED: string = 'paused';
  public readonly WORKLOG_STATE_STOPPED: string = 'stopped';

  public readonly WORKLOG_STATE_AWAIT_START: string = 'await_start';
  public readonly WORKLOG_STATE_AWAIT_PAUSE_START: string = 'await_pause_start';
  public readonly WORKLOG_STATE_AWAIT_PAUSE_END: string = 'await_pause_end';

  httpOptions = {};

  public constructor(private http: HttpClient) {}

  setQueryDate(date: any): void {
    this.queryDate = date;
  }

  onLoadWeekData(): Observable<any> {
    return this.subject.asObservable();
  }

  onCheckin(): Observable<any> {
    return this.checkedInSubject.asObservable();
  }

  onPause(): Observable<any> {
    return this.pauseSubject.asObservable();
  }

  loadWeekData(calculateDayLogs: any): Observable<any> {
    return this.http.get(this.apiEndpointWorktimes + this.buildDateQueryString()).pipe(
      map((response: any) => {
        this.subject.next({ calculateDayLogs, response });
        return response;
      }),
    );
  }

  logToDate(log) {
    if (!log.mins.length || !log.hours.length) {
      return null;
    }
    const date = moment();
    const dateStartMins = log.mins;
    const dateStartHours = log.hours;
    date.minutes(dateStartMins);
    date.hours(dateStartHours);
    date.seconds(0);
    return date;
  }
  getLogState(log) {
    const diffChekin = moment().diff(log.checkin);
    const diffChekout = moment().diff(log.checkout);
    log.completed = log.checkin && log.checkout;
    log.futureStart = diffChekin < 0;
    log.futureEnd = diffChekout < 0;
  }

  timeAsString(time) {
    if (!time) {
      time = 0;
    }
    return timePresentation(time, true);
  }

  calculateCurrentWorklogState(worklogs) {
    if (!worklogs) {
      return false;
    }

    worklogs.data.forEach((worklog) => {
      worklog.checkin = this.logToDate(worklog.checkin);
      worklog.checkout = this.logToDate(worklog.checkout);
      this.getLogState(worklog);
      worklog.hasBreaks = false;

      if (worklog.hasOwnProperty('breaks')) {
        worklog.breaks.forEach((workBreak) => {
          workBreak.checkin = this.logToDate(workBreak.checkin);
          workBreak.checkout = this.logToDate(workBreak.checkout);
          this.getLogState(workBreak);
        });
        worklog.hasBreaks = true;
      }

      worklog = this.calculateWorklogQuota(worklog);

      // WORKLOG IS COMPLETE
      if (worklog.completed) {
        worklog.state = this.WORKLOG_STATE_STOPPED;
        worklog.elapsed = 0;
        return;
      }
      //WORKLOG IS INCOMPLETE
      // WORKLOG WILL START IN FUTURE
      if (worklog.futureStart) {
        worklog.state = this.WORKLOG_STATE_AWAIT_START;
        worklog.awaitActionTime = worklog.checkin.format('HH:mm');

        const diff = Math.round(worklog.checkin.diff(moment()) / 1000);

        worklog.elapsed = -diff;

        this.scheduleChange(worklog, diff, this.WORKLOG_STATE_RUNNING);
        return;
      }
      //WORKLOG STARTS IN THE PAST OR HASN'T STARTED
      // WORKLOG HASN'T STARTED
      if (!worklog.checkin) {
        worklog.state = this.WORKLOG_STATE_NEW;
        worklog.elapsed = 0;
        return;
      }
      // WORKLOG HAS STARTED
      // WORKLOG IS RUNNING AND HAS NO BREAKS
      if (!worklog.hasBreaks) {
        worklog.state = this.WORKLOG_STATE_RUNNING;
        worklog.elapsed = Math.round(moment().diff(worklog.checkin) / 1000);
        return;
      }
      // WORKLOG IS RUNNING AND HAS POSSIBLY FUTURE BREAK(S)
      // USE ONLY FIRST ACTION THAT NEED SCHEDULING
      let hasScheduledAction = false;

      worklog.breaks.forEach((workBreak, index) => {
        // WAITING FOR PAUSE START IN FUTURE
        if (workBreak.futureStart && !hasScheduledAction) {
          worklog.state = this.WORKLOG_STATE_AWAIT_PAUSE_START;
          worklog.awaitActionTime = workBreak.checkin.format('HH:mm');

          // if first Break use time since checkin as elapsed
          // else use time since last break end as elapsed
          const elapsed = index === 0 ? worklog.checkin : worklog.breaks[index - 1].checkout;
          worklog.elapsed = Math.round(moment().diff(elapsed) / 1000);

          const diff = workBreak.checkin.diff(moment()) / 1000;

          this.scheduleChange(worklog, diff, this.WORKLOG_STATE_PAUSED);
          hasScheduledAction = true;

          // PAUSE HAS STARTED AND MAY NOT HAVE ENDED
        }
        if (!workBreak.futureStart) {
          // WAITING FOR PAUSE TO END
          if (workBreak.futureEnd && !hasScheduledAction) {
            worklog.state = this.WORKLOG_STATE_AWAIT_PAUSE_END;
            worklog.awaitActionTime = workBreak.checkout.format('HH:mm');

            const diff = workBreak.checkout.diff(moment()) / 1000;

            this.scheduleChange(worklog, diff, this.WORKLOG_STATE_RUNNING);
            hasScheduledAction = true;
            return;
            // PAUSE HASN'T ENDED
          }
          if (!workBreak.futureEnd && !workBreak.completed) {
            worklog.state = this.WORKLOG_STATE_PAUSED;
            return;
            // PAUSE HAS ENDED IN THE PAST
          } else {
            worklog.state = this.WORKLOG_STATE_RUNNING;
            worklog.elapsed = Math.round(moment().diff(worklog.breaks[worklog.breaks.length - 1].checkout) / 1000);
            return;
          }
        }
      });
    });
    return worklogs;
  }

  scheduleChange(worklog, timeDiffInSeconds, newState) {
    const schedule = new Schedule();
    timeDiffInSeconds = Math.abs(Math.round(timeDiffInSeconds));

    const inFuture = moment().add(timeDiffInSeconds, 'seconds');
    schedule.runCallbackAtTime(inFuture, () => {
      //worklog.switchToState = newState;
      //this.changeDetectorRef.detectChanges();
      this.loadWeekData(true);
    });
  }

  calculateWorklogQuota(worklog) {
    let completedBreakTime = 0;

    (worklog?.breaks || []).forEach((workBreak) => {
      // finished break
      if (workBreak.completed) {
        const diff = workBreak.checkout.diff(workBreak.checkin);
        completedBreakTime += Math.round(diff / 1000);
      }
    });

    if (!worklog.completed && !worklog?.breaks) {
      worklog.gross_time = this.timeAsString(0);
      worklog.net_time = this.timeAsString(0);
      return worklog;
    }

    if (worklog.completed) {
      const grossTimeNum = Math.round(worklog.checkout.diff(worklog.checkin) / 1000);
      worklog.gross_time = this.timeAsString(grossTimeNum);
      worklog.net_time = this.timeAsString(grossTimeNum - completedBreakTime);
      return worklog;
    }
    if (!worklog.completed && worklog?.breaks) {
      let totalBreakTime = 0;

      worklog.breaks.forEach((workBreak) => {
        if (workBreak.completed && !workBreak.futureStart && !workBreak.futureEnd) {
          const diff = workBreak.checkout.diff(workBreak.checkin);
          totalBreakTime += Math.round(diff / 1000);
        }

        if (!workBreak.completed) {
          const diff = moment().diff(workBreak.checkin);
          totalBreakTime += Math.round(diff / 1000);
        }
      });

      const grossTimeNum = Math.round(Math.abs(worklog.checkin.diff(moment()) / 1000));
      worklog.gross_time = this.timeAsString(grossTimeNum);

      worklog.net_time = this.timeAsString(grossTimeNum - totalBreakTime);
    }
    return worklog;
  }

  checkin(): Observable<any> {
    return this.http.post<ResponseStatusInterface>(this.apiEndpointCheckin, this.httpOptions).pipe(
      map((response) => {
        if (response.success) {
          this.checkedInSubject.next(true);
        }
        return response;
      }),
    );
  }
  checkout(): Observable<any> {
    return this.http.post<ResponseStatusInterface>(this.apiEndpointCheckout, this.httpOptions).pipe(
      map((response) => {
        if (response.success) {
          this.checkedInSubject.next(false);
        }
        return response;
      }),
    );
  }
  startPause(): Observable<any> {
    return this.http.post<ResponseStatusInterface>(this.apiEndpointStartPause, this.httpOptions).pipe(
      map((response) => {
        if (response.success) {
          this.pauseSubject.next(true);
        }
        return response;
      }),
    );
  }
  stopPause(): Observable<any> {
    return this.http.post<ResponseStatusInterface>(this.apiEndpointStopPause, this.httpOptions).pipe(
      map((response) => {
        if (response.success) {
          this.pauseSubject.next(false);
        }
        return response;
      }),
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO TOAST
      console.error(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private buildDateQueryString(): string {
    if (!this.queryDate) {
      return '';
    }
    return `?date=${this.queryDate.format('YYYY-MM-DD')}`;
  }
}
