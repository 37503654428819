import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash';
import { Subscription, first } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SettingsEmitter } from '../../core/emitters/settings.emitter';
import { StorageNotificationService } from '../../core/notifications/storageNotification.service';
import { LanguagesService } from '../../core/services/languages.service';
import { UserService } from '../../core/user/user.service';
import { TroiBreadcrumbsComponent } from '../../shared/troi-breadcrumbs/troi-breadcrumbs.component';
import { CurrencyMoneyInterface, FiltersInterface } from '../../shared/troi-data-listing-filters/filters.interface';
import { TroiDropdownSelectComponent } from '../../shared/troi-dropdown-select/troi-dropdown-select.component';
import { FilterSetSavedInterface } from '../../shared/troi-filter-sets/interfaces/filter-set-saved.interface';
import { DeleteConfirmationComponent } from '../../shared/troi-filter-sets/modals/delete-confirmation/delete-confirmation.component';
import { SaveFilterSetComponent } from '../../shared/troi-filter-sets/modals/save-filter-set/save-filter-set.component';
import { FilterSetModel } from '../../shared/troi-filter-sets/models/filter-set.model';
import { DomService } from '../../shared/troi-modals/dom.service';
import { ModalService } from '../../shared/troi-modals/modal.service';
import { TroiUserConfirmationComponent } from '../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { UserConfirmationEventEnum } from '../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { UserConfirmationSubscriber } from '../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { StopwatchService } from '../desk/widgets/stopwatch/stopwatch.service';
import { CreateTaskModalComponent } from './components/modals/create-task-modal/create-task-modal.component';
import { ImportCalcPosModalComponent } from './components/modals/import-calc-pos-modal/import-calc-pos-modal.component';
import { MoveCopyTaskModalComponent } from './components/modals/move-copy-task-modal/move-copy-task-modal.component';
import { ReminderTaskModalComponent } from './components/modals/reminder-task-modal/reminder-task-modal.component';
import { Routes } from './enum/routes';
import { TaskActionsEnum } from './enum/task-actions';
import { TaskModalPagesEnum } from './enum/task-modal-pages';
import { AssigneeInterface } from './interfaces/assignee.interface';
import { ReminderInterface } from './interfaces/reminder.interface';
import { ReminderResponseInterface, TasksResponseInterface } from './interfaces/responses.interface';
import { SettingsModel } from './models/settings.model';
import { TaskModel } from './models/task.model';
import { GanttchartviewComponent } from './modules/ganttchartview/ganttchartview.component';
import { ColumnsService } from './modules/listview/services/columns.service';
import { ExportService } from './network/export.service';
import { ReminderService } from './network/reminder.service';
import { SubtaskService } from './network/subtask.service';
import { TasksService } from './network/tasks.service';
import { FiltersFormService } from './services/filters-form.service';
import { TaskFiltersService } from './services/filters.service';
import { TasksHelperService } from './services/helper.service';
import { TasksSettingsService } from './services/tasks-settings.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {
  public btnNewTask = 'Tasks.listview.labels.actions.newTask';
  public btnExportTasks = 'Tasks.listview.labels.actions.exportList';
  public btnImportTasks = 'Tasks.listview.labels.actions.import';

  private taskDeleted = new EventEmitter<string>();

  private subscriptions: Subscription = new Subscription();

  private filterSetCreated = new EventEmitter<FilterSetSavedInterface>();
  private taskCreated = new EventEmitter<string>();
  public startDate: any;
  public endDate: any;

  /**
   * A flag indicating whether the range should be updated.
   *
   * @private
   */
  private updateRange = false;

  private projectId: string;
  public isProjectContext = false;
  public createNewTask = false;
  public isResourcesContext = false;
  public isTimelineContext = false;
  public disableDropdownThird = false;

  currencyMoneyData: CurrencyMoneyInterface;

  public filters: FiltersInterface = cloneDeep(this.filtersService.actualFilters);

  @ContentChildren(TroiDropdownSelectComponent) dropdowns: QueryList<TroiDropdownSelectComponent>;

  @ViewChild(TroiBreadcrumbsComponent) breadcrumbs: TroiBreadcrumbsComponent;

  constructor(
    public helperService: TasksHelperService,
    public tasksService: TasksService,
    private exportService: ExportService,
    public filtersService: TaskFiltersService,
    public settingsService: TasksSettingsService,
    public columnsService: ColumnsService,
    public languagesService: LanguagesService,
    public modalService: ModalService,
    private filtersFormService: FiltersFormService,
    private notificationService: StorageNotificationService,
    private settingsEmitter: SettingsEmitter,
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
    private reminderService: ReminderService,
    public userService: UserService,
    private translate: TranslateService,
    private subtaskService: SubtaskService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private stopwatchService: StopwatchService,
  ) {
    this.filtersService.basicFiltersService.clientsLoaded.subscribe(() => {
      this.tasksService.defaultClientId = filtersService.getDefaultClientId();
    });

    const queryParams = new URLSearchParams(window.parent.location.search);
    const projectContext = queryParams.get('projectContext');
    const projectId = queryParams.get('projectId');
    const createNewTask = queryParams.get('create_new_task');

    const isResourcesContext = GanttchartviewComponent === this.getOutletComponentType();

    this.isTimelineContext = this.router.url.includes('timelineview');

    if (isResourcesContext) {
      this.helperService.setResourcesContext(true);
      this.isResourcesContext = true;
    }

    if (projectContext) {
      this.isProjectContext = true;
      this.helperService.setProjectContext(true);
    }

    if (projectId) {
      this.helperService.setProjectId(projectId);
      this.projectId = projectId;
    }

    if (createNewTask) {
      this.createNewTask = true;
    }

    if (this.router.url.includes('timelineview')) {
      this.isTimelineContext = true;
      this.helperService.setTimelineContext(true);
    }
  }

  ngOnInit(): void {
    if (this.settingsService.settings && this.filtersService.getSelectedClientId()) {
      this.settingsService
        .prepareSettings(this.filtersService.getSelectedClientId())
        .subscribe(this.handlePrepareSettingsResponse);
    } else {
      this.subscriptions.add(
        this.filtersService.defaultClientAssigned.subscribe(() => {
          this.emitFiltersChanged(true, false);
          this.settingsService
            .prepareSettings(this.filtersService.getSelectedClientId(), true)
            .subscribe(this.handlePrepareSettingsResponse);
        }),
      );
    }

    this.subscriptions.add(
      this.tasksService.projectOptionsLoaded.subscribe((isLoaded: boolean) => {
        if (isLoaded) this.filtersService.generateSecondDropdown();
      }),
    );

    this.subscriptions.add(
      this.filterSetCreated.subscribe((filterSetSaved: FilterSetSavedInterface) => {
        if (filterSetSaved.isNew) {
          this.filtersService.addFilterSet(filterSetSaved.filterSet);
        } else {
          this.filtersService.updateFilterSetValue(filterSetSaved.filterSet);
        }

        this.filtersService.areFiltersEdited = false;
        this.filtersService.selectedFilterSetId = filterSetSaved.filterSet.id;
        this.filtersService.filterSetSelected.next(
          filterSetSaved.isNew ? this.filtersService.selectedFilterSet : filterSetSaved.filterSet,
        );
      }),
    );

    this.subscriptions.add(
      this.taskCreated.subscribe((res: string) => {
        // reload task list including the new/edited one and create success message
        this.search();
        if (res === 'update')
          this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.task.updated'));
        else this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.task.created'));
      }),
    );

    this.subscriptions.add(
      this.filtersService.haveFiltersChanged.subscribe(() => {
        // load tasks when filter set is fully loaded and filters are preset
        this.filters = cloneDeep(this.filtersService.actualFilters);
        this.search();
      }),
    );

    this.subscriptions.add(
      this.reminderService
        .getUserReminder(this.userService.getUser().id.toString())
        .subscribe((res: ReminderResponseInterface) => {
          this.reminderService.reminders = res.data as ReminderInterface[];
        }),
    );

    this.checkSharedLink();
    this.checkTaskActions();
    if (this.isTimelineContext) this.prepareTimelineRangeFilter();
  }

  ngAfterViewInit() {
    if (this.breadcrumbs) this.initializeSettingsReference();

    this.updateRange = true;
  }

  /**
   * Initializes the settings reference for the filters service.
   *
   * This method sets the `settingsReference` property of the `filtersService` to the
   * `bookingSettingsService` from the breadcrumbs if it exists. If it does not exist,
   * it defaults to an object with a nested `assignmentsAllClients` property set to `false`.
   *
   * @private
   */
  private initializeSettingsReference(): void {
    this.filtersService.settingsReference = (this.breadcrumbs?.bookingSettingsService as any) ?? {
      settings: { settings: { assignmentsAllClients: false } },
    };
  }

  public getOutletComponentType(): Type<any> {
    let childRoute = this.route.firstChild;

    while (childRoute.firstChild) {
      childRoute = childRoute.firstChild;
    }

    return childRoute.routeConfig?.component;
  }

  /**
   * Updates the timeline range with the provided start and end dates.
   *
   * @param param0 - An object containing the validity status and date range.
   * @param param0.isValid - A boolean indicating if the date range is valid.
   * @param param0.date - An array containing the start and end dates as numbers.
   * @param param0.date[0] - The start date.
   * @param param0.date[1] - The end date.
   *
   * @remarks
   * This method updates the timeline range by setting the new start and end dates.
   * It also updates the display range in the filters service and triggers a search
   * if the dates have changed. If the `updateRange` flag is not set, the method
   * returns early without making any changes.
   *
   * @throws Will catch and suppress any errors that occur during the update process.
   */
  public updateTimelineRange({ date: [startDate, endDate] }: { isValid: boolean; date: [number, number] }): void {
    if (!this.updateRange) return;
    try {
      this.helperService.setTimelineRangeChanged([startDate, endDate]);
      this.filtersService.actualFilters.displayRangeFrom = startDate;
      this.filtersService.actualFilters.displayRangeTo = endDate;
      if (this.startDate !== startDate || this.endDate !== endDate) this.search();
      this.startDate = startDate;
      this.endDate = endDate;
    } catch (error) {}
  }

  /**
   * Subscribes to task action events and performs the corresponding action based on the task action type.
   *
   * This method adds a subscription to `taskActionSub` from `helperService`. When a task action event is emitted,
   * it extracts the task, action, and page from the event and performs the corresponding action by calling the appropriate
   * method based on the action type.
   *
   * The following actions are handled:
   * - `TaskActionsEnum.COPY`: Calls `onCopyTaskClick` with the action and task.
   * - `TaskActionsEnum.DELETE_TASK`: Calls `onDeleteTaskClick` with the task.
   * - `TaskActionsEnum.MOVE_TASK`: Calls `onMoveTaskClick` with the action and task.
   * - `TaskActionsEnum.REMINDER`: Calls `onReminderClick` with the task.
   * - `TaskActionsEnum.RECORD_WORKING_TIME`: Calls `onRecordTimeClick` with the task.
   * - `TaskActionsEnum.START_STOPWATCH`: Calls `onStopwatchClick` with the task.
   * - `TaskActionsEnum.EDIT`: Calls `openEditTaskModal` with the task and page.
   *
   * @private
   */
  private checkTaskActions(): void {
    this.subscriptions.add(
      this.helperService.taskActionSub.subscribe((taskAction) => {
        const task = taskAction.task;
        const action = taskAction.value;
        const page = taskAction.page;

        switch (action) {
          case TaskActionsEnum.COPY:
            this.onCopyTaskClick(action, task);
            break;
          case TaskActionsEnum.DELETE_TASK:
            this.onDeleteTaskClick(task);
            break;
          case TaskActionsEnum.MOVE_TASK:
            this.onMoveTaskClick(action, task);
            break;
          case TaskActionsEnum.REMINDER:
            this.onReminderClick(task);
            break;
          case TaskActionsEnum.RECORD_WORKING_TIME:
            this.onRecordTimeClick(task);
            break;
          case TaskActionsEnum.START_STOPWATCH:
            this.onStopwatchClick(task);
            break;
          case TaskActionsEnum.EDIT:
            this.openEditTaskModal(task, page);
            break;
        }
      }),
    );
  }

  private checkSharedLink() {
    // wait until tasks and status dropdown options are loaded and check GET-Params for shared task url
    this.subscriptions.add(
      this.tasksService.listLoaded.subscribe((tasksLoaded: boolean) => {
        if (this.createNewTask) {
          this.openNewTaskModal();
          this.createNewTask = false;
        }

        this.subscriptions.add(
          this.tasksService.statusOptionsLoaded.subscribe((statusLoaded: boolean) => {
            if (statusLoaded && tasksLoaded) {
              // check if task id was shared via URL (Angular url)

              const taskId = +window.location.href.split('/').pop();
              const taskIdProjectContext = +window.location.href.split('=').pop();

              if (isNaN(taskId) && isNaN(taskIdProjectContext)) return;

              // remove GET-param without refresh after first load, otherwise it could cause errors after a task was e.g. deleted (page refresh)
              const url = new URL(window.parent.location.href);
              url.searchParams.delete('taskId');
              window.parent.history.replaceState({}, document.title, url.pathname + url.search);
              // check if id is valid and load edit modal if so
              const givenTask = !isNaN(taskId)
                ? this.tasksService.tasks.find((x) => x.id.toString() === taskId.toString())
                : this.tasksService.tasks.find((x) => x.id.toString() === taskIdProjectContext.toString());

              if (givenTask) {
                this.openEditTaskModal(givenTask);
              } else {
                this.tasksService.findTaskById(taskId).subscribe((res) => {
                  if (!res?.data?.length) {
                    return;
                  }
                  this.openEditTaskModal(res.data[0]);
                });
              }
            }
          }),
        );
      }),
    );
  }

  private search() {
    if (!this.isResourcesContext) {
      this.tasksService.loadTaskList(this.filtersService.putFiltersInUrl(environment.url + Routes.TASKS));
    } else {
      this.tasksService.loadTaskList(this.filtersService.putFiltersInUrl(environment.url + Routes.RESOURCES, true));
    }
  }

  /**
   * Handles changes to the client filter and updates the state of the second dropdown accordingly.
   * If the first dropdown filter is set to '*', the second dropdown is disabled.
   * Otherwise, the second dropdown is enabled.
   *
   * @param filters - The filters object containing the current state of the dropdowns.
   * @returns A promise that resolves when the new filters have been applied.
   */
  public async clientChanged(filters: FiltersInterface): Promise<void> {
    if ((filters.dropdownFirst as any) === '*') {
      this.filtersService.thirdDropdown.disable = true;
      this.disableDropdownThird = true;
    } else {
      this.filtersService.thirdDropdown.disable = false;
      this.disableDropdownThird = false;
    }

    await this.filtersService.applyNewFilters(filters);
  }

  public getHeadlineTitle() {
    if (this.router.url.includes('listview')) {
      return 'Tasks.listview.labels.header';
    } else if (this.router.url.includes('kanban')) {
      return 'Tasks.kanbanView.header';
    } else if (this.router.url.includes('resources')) {
      return 'Tasks.resourcesView.header';
    }

    return 'Tasks.timelineView.header';
  }

  public filterChanged(filters: FiltersInterface, third = false): void {
    this.filtersService.syncDoubleFilterOptions('priority', filters);
    this.filtersService.syncDoubleFilterOptions('projectId', filters);
    this.filtersService.applyNewFilters(filters);
  }

  public filterReset() {
    this.filtersService.resetFilters();
    this.updateTimelineRange({ isValid: true, date: this.filtersService.defaultTimelineRangeValues() });
    this.emitFiltersChanged();
    this.search();
  }

  public getClient(): number {
    return this.filtersService.getSelectedClientId() ?? this.filtersService.getDefaultClientId();
  }

  public filterSetSelected(filterSetId: string | number): void {
    this.filtersService.areFiltersEdited = false;
    this.filtersService.selectedFilterSetId = filterSetId;
    this.filtersService.filterSetSelected.next(this.filtersService.selectedFilterSet);
  }

  public filterSetDeleted(filterSet: FilterSetModel): void {
    this.modalService.init(
      DeleteConfirmationComponent,
      {
        executeCallback: () => this.filtersService.deleteFilterSet(filterSet),
      },
      {},
      '500px',
      '500px',
      true,
    );
  }

  public filterSetAsDefault(filterSet: FilterSetModel): void {
    this.filtersService.updateFilterSet(filterSet, true);
  }

  public openFilterSetSaveModal(): void {
    this.modalService.object = {
      event: null,
      object: {
        ...this.filtersService.selectedFilterSet,
        filters: this.filtersService.actualFilters,
        value: this.filtersService.generateUrlParams(
          this.filtersFormService.prepareSearchRequestParams(this.filtersService.actualFilters),
          true,
        ),
      },
    };
    this.modalService.init(SaveFilterSetComponent, {}, { filterSetCreated: this.filterSetCreated }, '500px', '600px');
  }

  public openNewTaskModal(): void {
    this.modalService.init(
      CreateTaskModalComponent,
      {
        clientId: this.getClient(),
      },
      { taskCreated: this.taskCreated },
      '1800px',
      '90%',
      false,
      '',
      '1200px',
    );
  }

  public openExportModal(): void {
    this.subscriptions.add(
      this.exportService
        .exportTasklist(
          this.filtersService.actualFilters,
          this.tasksService.statusOptions.map((a) => a.id),
        )
        .subscribe((res) => {
          const exportData: Blob = new Blob([res], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(exportData, 'taskExport.csv');
        }),
    );
  }

  public openImportModal(): void {
    this.modalService.init(ImportCalcPosModalComponent, {}, {}, '900px', '1200px');
  }

  private emitFiltersChanged(generateCustomers = false, emit = true, reloadProjects = false): void {
    if (generateCustomers) {
      this.filtersService.generateFirstDropdown();
    }

    if (emit) {
      this.filtersService.haveFiltersChanged.next(this.filtersService.actualFilters);
    }
  }

  public openEditTaskModal(task: TaskModel, page?: TaskModalPagesEnum): void {
    this.modalService.init(
      CreateTaskModalComponent,
      {
        editableTask: task,
        curPage: page ? page : TaskModalPagesEnum.GENERAL,
      },
      { taskDeleted: this.taskDeleted, taskCreated: this.taskCreated },
      '900px',
      '1200px',
    );
  }

  onDeleteTaskClick(task: TaskModel) {
    const modalService = new ModalService(this.domService);
    const translations = {
      title: 'Tasks.labels.deleteTaskModal.title',
      executeBtnTitle: 'Common.labels.continue',
      cancelBtnTitle: 'Common.labels.cancel',
      description: 'Tasks.labels.deleteTaskModal.description',
    };

    modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

    this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
      if (result === UserConfirmationEventEnum.EXECUTE) {
        this.subscriptions.add(
          this.tasksService.deleteTask(task).subscribe(() => {
            this.search();
            this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.task.deleted'));
          }),
        );

        this.modalService.destroy();
      }
    });
  }

  onCopyTaskClick(action: TaskActionsEnum, task: TaskModel) {
    const modalService = new ModalService(this.domService);

    const translations = {
      title: 'Tasks.labels.moveCopyModal.copyTitle',
      executeBtnTitle: 'Common.labels.copy',
      cancelBtnTitle: 'Common.labels.cancel',
    };

    modalService.init(
      MoveCopyTaskModalComponent,
      {
        translations,
        task,
        action,
        taskId: this.tasksService.tasks[this.tasksService.tasks.length - 1].id + 1,
      },
      {},
      '400px',
      '550px',
      true,
    );

    this.confirmationSubscriber.actionWithData.pipe(first()).subscribe((result) => {
      if (result.event === UserConfirmationEventEnum.EXECUTE) {
        const copiedTask = result.data.task;
        const tasksSubtaks = result.data.task.subtasks;

        this.resetTaskValues(copiedTask);
        this.subscriptions.add(
          this.tasksService.createTask(copiedTask).subscribe((resTask: TasksResponseInterface) => {
            const createdTask = resTask.data[0];

            tasksSubtaks.forEach((subtask) => {
              this.subtaskService.createSubtask(null, createdTask, subtask).subscribe();
            });
            this.search();
            this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.task.copied'));

            this.modalService.destroy();
          }),
        );
      }
    });
  }

  private resetTaskValues(task: TaskModel) {
    task.attachments = task.comments = task.startDate = task.endDate = null;
  }

  public onMoveTaskClick(action: TaskActionsEnum, task: TaskModel): void {
    const alreadyAssignedEmployees = [...task.assignees];

    const modalService = new ModalService(this.domService);

    const translations = {
      title: 'Tasks.labels.moveCopyModal.moveTitle',
      executeBtnTitle: 'Common.labels.move',
      cancelBtnTitle: 'Common.labels.cancel',
    };

    modalService.init(MoveCopyTaskModalComponent, { translations, task, action }, {}, '400px', '550px', true);
    this.confirmationSubscriber.actionWithData.pipe(first()).subscribe((result) => {
      if (result.event === UserConfirmationEventEnum.EXECUTE) {
        this.subscriptions.add(
          this.tasksService.updateTask(result.data.task).subscribe((res) => {
            this.search();

            this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.task.moved'));
            this.modalService.destroy();
          }),
        );
      }
    });
  }

  private prepareTimelineRangeFilter(): void {
    this.startDate = this.filtersService.defaultTimelineRangeValues()[0];
    this.endDate = this.filtersService.defaultTimelineRangeValues()[1];

    this.helperService.setTimelineRangeChanged([this.startDate, this.endDate]);
    this.filtersService.actualFilters.displayRangeFrom = this.startDate;
    this.filtersService.actualFilters.displayRangeTo = this.endDate;
  }

  private getNewAssignedEmployees(alreadyAssignedEmployees: AssigneeInterface[], task: TaskModel): AssigneeInterface[] {
    const alreadyAssignedIds = new Set(alreadyAssignedEmployees.map((assignee) => assignee.id));
    return task.assignees.filter((assignee) => !alreadyAssignedIds.has(assignee.id));
  }

  public onReminderClick(task: TaskModel): void {
    const reminder = this.reminderService.getTaskReminder(task.id);

    const modalService = new ModalService(this.domService);
    const translations = {
      title:
        reminder.length === 0
          ? 'Tasks.labels.reminderTaskModal.titleActivate'
          : 'Tasks.labels.reminderTaskModal.titleChange',
      executeBtnTitle: reminder.length === 0 ? 'Common.labels.activate' : 'Common.labels.change',
      cancelBtnTitle: 'Common.labels.cancel',
    };

    modalService.init(
      ReminderTaskModalComponent,
      { translations, task, reminder: reminder[0], user: this.userService.getUser() },
      {},
      '400px',
      '500px',
      true,
    );

    this.confirmationSubscriber.actionWithData.pipe(first()).subscribe((result) => {
      if (result.event === UserConfirmationEventEnum.EXECUTE) {
        this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.reminder.set'));
        this.modalService.destroy();
      }
    });
  }

  public onRecordTimeClick(task: TaskModel): void {
    this.modalService.init(
      CreateTaskModalComponent,
      {
        editableTask: task,
        curPage: TaskModalPagesEnum.TIMEENTRIES,
      },
      {},
      '900px',
      '1200px',
    );
  }

  public onStopwatchClick(task: TaskModel): void {
    this.stopwatchService.createAndStart(this.stopwatchService.preparePayloadFromTask(task)).subscribe();
  }

  private handlePrepareSettingsResponse = (settings: SettingsModel) => {
    this.settingsEmitter.setSettings(settings);
    this.filtersService.reloadFilterChips.next(this.filtersService.actualFilters.filters);
    return;
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
