import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StorageNotificationService } from '../../../app/core/notifications/storageNotification.service';
import { StorageKeys } from '../../core/storage/storage.keys';
import { StorageService } from '../../core/storage/storage.service';
import { StopwatchService } from '../../modules/desk/widgets/stopwatch/stopwatch.service';
import { IBoilerPlatesFormatted } from '../../modules/time-recording/interfaces/boilerPlates.interface';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';
@Component({
  selector: 'troi-my-projects',
  templateUrl: './troi-my-projects.component.html',
  styleUrls: ['./troi-my-projects.component.scss'],
})
export class TroiMyProjectsComponent implements OnInit {
  reloadListEmitter = new EventEmitter();
  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() AddnewProject = new EventEmitter<any>();
  public loading = false;
  public isEditable = false;
  public cloneData = false;
  private prevScrollPosition = 0;

  searchInput = new Subject<string>();
  LogData: any[] = [];
  originalLogData: any = [];
  url: any;
  userId: number;
  pageNumber = 0;
  showError = false;
  showErrorMessage: string;
  dateFor: string;
  isTimeRecordingExternalCommentRequired = false;
  isTimeRecordingInternalCommentRequired = false;
  isEnableTimerecordingAdditionalFields = false;
  isRestExpenseEstimationActive = false;
  isDisplayDebitActual = false;
  public fromDate = 0;
  public toDate = 0;
  public backwardDays;

  filters: any = {
    search: '',
    clientId: '',
    customerId: '',
    projectId: '',
  };

  public boilerPlates!: IBoilerPlatesFormatted;
  public isEnabledBoilerPlatesInComments = false;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    public timeService: TimeRecordingService,
    public stopwatchService: StopwatchService,
    private cookieService: CookieService,
    private translate: TranslateService,
    private notificationService: StorageNotificationService,
  ) {
    this.searchInput.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(() => {
      if (this.filters.search.length > 2 || this.filters.search.length === 0) {
        this.emitFiltersChanged();
      }
    });

    this.fromDate = Date.now();
    this.toDate = Date.now();
  }

  ngOnInit() {
    this.isEditable =
      this.timeService.addProjectDateGet()?.some((date) => this.timeService.checkIsEditable(date, 'P')) || false;

    const cProjectData = this.storageService.getItem(StorageKeys.CLONE_PROJECT_DATA) || {};
    if (cProjectData) {
      if (cProjectData['isCloneProject']) {
        this.cloneData = true;
      }
    }

    this.prevScrollPosition = 0;
    this.pageNumber = 0;
    /* Setting Get*/
    this.timeService.getTimerecordingSettings().subscribe(() => {
      const user = this.storageService.getItem(StorageKeys.USER);
      if (user) {
        this.userId = user.id;
      }

      /* check validation for internal and external comment */
      const timerecordingSettings = this.storageService.getItem(StorageKeys.TIMERECORDING_SETTINGS);
      if (timerecordingSettings) {
        const settingsToCheck = [
          'isTimeRecordingExternalCommentRequired',
          'isTimeRecordingInternalCommentRequired',
          'isEnableTimerecordingAdditionalFields',
          'isRestExpenseEstimationActive',
          'isDisplayDebitActual',
          'isEnabledBoilerPlatesInComments',
        ];

        settingsToCheck.forEach((setting) => {
          if (timerecordingSettings.settings[setting]) {
            this[setting] = true;
          }
        });

        if (this.isEnabledBoilerPlatesInComments) {
          this.fillBoilerPlates();
        }
      }
      this.getMyProjects();
    });
  }

  private getPayloadForStopwatch(project) {
    return {
      calculationPositionId: project.cp.cpId,
      date: new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split('T')[0],
      name: project.project.projectName,
      billable: project.cp.cpIsBillable,
    };
  }

  public async fillBoilerPlates(): Promise<void> {
    try {
      const response = await new Promise<IBoilerPlatesFormatted>((resolve) => {
        this.timeService.getBoilerPlates().subscribe((data) => {
          resolve(data);
        });
      });
      if (response) {
        this.boilerPlates = response;
      } else {
        this.notificationService.showError(this.translate.instant('Common.error.general'));
      }
    } catch (error) {
      this.notificationService.showError(this.translate.instant('Common.error.general'));
    }
  }

  toggleStopwatchVisibility(project): void {
    this.stopwatchService.createAndStart(this.getPayloadForStopwatch(project)).subscribe();
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const debouncedHandler = _.debounce(() => {
      const container = document.getElementById('endOfContent') as HTMLElement;

      if (container) {
        const scrollTop = container.scrollTop || document.documentElement.scrollTop;
        if (scrollTop > this.prevScrollPosition) {
          const scrollHeight = container.scrollHeight;
          const clientHeight = container.clientHeight;

          if (scrollTop + clientHeight >= scrollHeight - 200 && !this.loading) {
            this.pageNumber++;
            this.getMyProjects();
          }
        }
        this.prevScrollPosition = scrollTop;
      }
    }, 1000);
    debouncedHandler();
  }

  /* Get All Projects */
  getMyProjects() {
    this.dateFor = this.timeService.addProjectDateGet()[0];
    this.fromDate = new Date(this.dateFor).valueOf();
    this.toDate = new Date(this.dateFor).valueOf();

    const params = {
      clientId: this.filters.clientId ?? '',
      customerId: this.filters.customerId ?? '',
      projectId: this.filters.projectId ?? '',
      simpleSearch: this.filters.search ?? '',
      page: this.pageNumber,
      employeeId: this.timeService.addProjectUserIdGet(),
    };

    this.loading = true;
    this.timeService.getMyProjects(params).subscribe(
      (response: any) => {
        this.loading = false;
        this.LogData = this.LogData.concat(response.entries);
        this.setData(response.entries);
      },
      (error) => {
        this.loading = false;
      },
    );
  }

  setData(entries: any[], forceCloseEditMode = false) {
    this.originalLogData = [...this.LogData];
    entries.forEach((item, index) => {
      item.cp.editMode = forceCloseEditMode ? false : item.cp.editMode ?? false;
      item.cp.internalCommentError = false;
      item.cp.externalCommentError = false;
      item.cp.timeError = false;
      // item.billing.billingQuantityTime = "00:00";
      item.billing = {
        billingQuantityTime: '',
        billingInternalComment: '',
        billingExternalComment: '',
        billingIsBillable: item.cp.cpIsBillable,
      };

      if (this.isEnableTimerecordingAdditionalFields) {
        item.billing.billingActualHours = '';
      }

      if (this.isRestExpenseEstimationActive) {
        item.billing.billingEstimation = '';
      }

      item.cp.percentDisplay = 100 < item.cp.cpPercentUsed ? 100 : item.cp.cpPercentUsed;
      item.cp.percentDisplay = 0 > item.cp.cpPercentUsed ? 0 : item.cp.cpPercentUsed;

      if (0 === item.cp.cpPlannedUnits && 0 < item.cp.cpUsedUnits) {
        item.cp.percentDisplay = 100;
      }
    });
  }

  toggleEditMode(indexId: number) {
    this.showError = false;
    this.showErrorMessage = '';

    this.dateFor = this.timeService.addProjectDateGet()[0];
    this.fromDate = new Date(this.dateFor).valueOf();
    this.toDate = new Date(this.dateFor).valueOf();

    this.LogData.forEach((item, currentIndex) => {
      if (currentIndex !== indexId) {
        item.cp.editMode = false;
      }
    });
    const object = this.LogData[indexId];
    if (object) {
      const objectCopy = cloneDeep(object);
      objectCopy.cp.editMode = !objectCopy.cp.editMode;
      this.LogData[indexId] = objectCopy;
    }
  }

  dateChanged(pickedDate: any): void {
    if ('undefined' === typeof pickedDate.date) {
      return;
    }
    const date: number = pickedDate.date;
    const dateFromatted: string = format(new Date(date[0]), 'yyyy-MM-dd');
    this.dateFor = dateFromatted;
  }

  onFavClick(item: any, index: number): void {
    const uId = this.timeService.addProjectUserIdGet();
    if (!uId) {
      return;
    }

    const newJson = {
      objectType: 'cp',
      objectId: item.cp.cpId,
    };

    if (item.cp.cpIsFavorite) {
      this.url = this.timeService.FavUnFavUpdate(uId, newJson, 'unfavorite');
      if (this.url) {
        this.url.subscribe((response: any) => {
          this.LogData[index].cp.cpIsFavorite = !this.LogData[index].cp.cpIsFavorite;
        });
      }
    } else {
      this.url = this.timeService.FavUnFavUpdate(uId, newJson, 'favorite');
      if (this.url) {
        this.url.subscribe((response: any) => {
          this.LogData[index].cp.cpIsFavorite = !this.LogData[index].cp.cpIsFavorite;
        });
      }
    }
  }

  /* this is for filters */
  emitFiltersChanged() {
    this.LogData = [];
    this.pageNumber = 0;
    if (this.filters.search.length < 3) {
      this.filters.search = '';
      this.getMyProjects();
    } else {
      this.getMyProjects();
    }
  }

  onOpenStateChanged($event: any) {
    this.openStateChanged.emit(true);
  }

  filtersApplied(filtersChanged) {
    this.LogData = [];
    this.pageNumber = 0;
    this.filters.clientId = filtersChanged[0]['clientId'];
    this.filters.customerId = filtersChanged[0]['customerId'];
    this.filters.projectId = filtersChanged[0]['projectId'];
    this.getMyProjects();
  }

  emitFiltersReset() {
    this.LogData = this.originalLogData;
    this.filters.clientId = '';
    this.filters.customerId = '';
    this.filters.projectId = '';
    this.getMyProjects();
  }

  AddnewProjectItem(item) {
    /* Check this project cloneing data */
    const cloneProjectData = this.storageService.getItem(StorageKeys.CLONE_PROJECT_DATA) || {};
    if (cloneProjectData) {
      if (cloneProjectData['isCloneProject']) {
        const confirmDelete = confirm(this.translate.instant('Timerecording.are_you_sure_want_to_repace_this_project'));
        if (!confirmDelete) {
          return;
        }
        cloneProjectData['sidebarLastcpId'] = item.cp.cpId;

        this.storageService.setItem(StorageKeys.CLONE_PROJECT_DATA, cloneProjectData);
      }
    }

    const rootUserid = this.userId;
    const addProjectUserid = this.timeService.addProjectUserIdGet();
    const addProjectDate = this.timeService.addProjectDateGet();

    for (const [index, date] of addProjectDate.entries()) {
      if (!rootUserid || !addProjectUserid || !date) {
        return false;
      }

      const keyId = addProjectUserid + '_' + date;

      const itemsStr = this.cookieService.get('item');
      const itemsData = itemsStr ? JSON.parse(itemsStr) : {};

      if (!itemsData[rootUserid]) {
        itemsData[rootUserid] = {};
      }
      if (!itemsData[rootUserid][keyId]) {
        itemsData[rootUserid][keyId] = [];
      }

      // if (!itemsData[rootUserid][keyId].includes(item.cp.cpId)) {
      itemsData[rootUserid][keyId].push(item.cp.cpId);

      const expirationDate = new Date();
      expirationDate.setHours(23, 59, 59, 999);
      if (!this.timeService.skipSidebarClone) {
        this.cookieService.set('item', JSON.stringify(itemsData), expirationDate);
      }
      if (index === addProjectDate.length - 1) {
        this.AddnewProject.emit(item);
      }
      // }
    }
    if (cloneProjectData['isCloneProject']) {
      this.timeService.toggleMyProjectsVisibility();
    }
    return;
  }

  getValueInputField(item: any, field: string, index: number) {
    const timeIsEmpty = !item.billing.billingQuantityTime || item.billing.billingQuantityTime === '00:00';
    if (this.LogData[index] && field === 'checkbox') {
      this.LogData[index].billing.billingIsBillable = !this.LogData[index].billing.billingIsBillable;
      return;
    }
    if (timeIsEmpty) {
      this.LogData[index].cp.timeError = true;
      return;
    }
    if (!item.billing.billingInternalComment.trim()) {
      this.LogData[index].cp.internalCommentError = this.isTimeRecordingInternalCommentRequired;
      return;
    } else if (!item.billing.billingExternalComment.trim()) {
      this.LogData[index].cp.externalCommentError = this.isTimeRecordingExternalCommentRequired;
      return;
    }

    this.LogData[index].cp.timeError = false;
    this.LogData[index].cp.internalCommentError = false;
    this.LogData[index].cp.externalCommentError = false;

    this.setDataForCreateAndUpdate(item);

    this.LogData[index].billing.billingQuantityTime = '';
    this.LogData[index].billing.billingInternalComment = '';
    this.LogData[index].billing.billingExternalComment = '';
  }

  setDataForCreateAndUpdate(itemData) {
    const uId = this.timeService.addProjectUserIdGet();
    if (!uId) {
      return;
    }

    if (!this.timeService.checkIsEditable(this.dateFor, 'P')) {
      this.notificationService.showError(this.translate.instant('Timerecording.you_can_not_add_log_for_this_date'));
      return;
    }
    if (!uId) {
      return;
    }
    const newJson = {
      calculationPosition: itemData.cp.cpId,
      date: this.dateFor,
      workTime: itemData.billing.billingQuantityTime,
      internalComment: itemData.billing.billingInternalComment,
      externalComment: itemData.billing.billingExternalComment,
      // billable: itemData.cp.cpNewAdded ? itemData.billing.billingIsBillable : !itemData.billing.billingIsBillable,
      billable: itemData.billing.billingIsBillable,
      projectTask: null,
      projectSubTask: null,
      employeeId: uId,
    };

    if (this.isEnableTimerecordingAdditionalFields) {
      newJson['actualHours'] = itemData.billing.billingActualHours;
    }

    if (this.isRestExpenseEstimationActive) {
      newJson['estimation'] = itemData.billing.billingEstimation;
    }

    // clearing itemData to reset form
    itemData.billing.billingQuantityTime = '';
    itemData.billing.billingInternalComment = '';
    itemData.billing.billingExternalComment = '';
    itemData.billing.billingIsBillable = itemData.cp.cpIsBillable;
    itemData.billing.billingActualHours = '';
    itemData.billing.billingEstimation = '';

    this.url = this.timeService.addProjectTimeLogData(uId, itemData.billing.billingId, newJson);
    if (this.url) {
      this.url.subscribe(
        (response: any) => {
          if (response.success) {
            this.notificationService.showSuccess(this.translate.instant('Common.labels.dataSuccessfullySaved'));
          } else {
            this.notificationService.showError(response.errorMessage || this.translate.instant('Common.error.general'));
          }

          this.LogData.forEach((logDataItem, currentIndex) => {
            logDataItem.cp.editMode = false;
          });
          this.loading = false;
          this.showError = false;
          this.showErrorMessage = '';
          this.timeService.reloading.next(true);
        },
        (error: any) => {
          this.loading = false;
          this.showError = true;
          if (error.error) {
            for (const key in error.error) {
              if (error.error.hasOwnProperty(key)) {
                const errorMessage = error.error[key][0];
                this.notificationService.showError(`Error for ${key}: ${errorMessage}`);
              }
            }
          } else {
            this.notificationService.showError(this.translate.instant('Common.error.general'));
          }
        },
      );
    }
  }

  saveData(project) {
    project.cp.timeError = !project.billing.billingQuantityTime || project.billing.billingQuantityTime === '00:00';

    if (!project.billing.billingInternalComment.trim()) {
      project.cp.internalCommentError = this.isTimeRecordingInternalCommentRequired;
    }

    if (!project.billing.billingExternalComment.trim()) {
      project.cp.externalCommentError = this.isTimeRecordingExternalCommentRequired;
    }

    const anyErrors = project.cp.timeError || project.cp.internalCommentError || project.cp.externalCommentError;
    if (!anyErrors) {
      this.setDataForCreateAndUpdate(project);
    }
  }

  public onEnterUp(event, project): void {
    event.preventDefault();
    if (project.billing.billingQuantityTime) {
      this.saveData(project);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'Escape':
        this.LogData = this.originalLogData;
        this.setData(this.LogData, true);
        // this.LogData.forEach((item) => {
        //   item.cp.editMode = false;
        // });
        break;
    }
  }
}
