<div class="files">
  <div class="files__header">
    <div class="files__header__list" *ngIf="!isDigitalInvoice">
      <span>{{ 'Booking.labels.file' | translate }}</span>
      <troi-dropdown-select
        [returnSelectedObject]="true"
        (selected)="filePreviewSelected($event.fullObject)"
        [size]="'11px'"
        [initValue]="actualFile?.id"
        class="troi-dropdown-select--full"
        placeholder="{{ 'Booking.labels.pleaseSelect' | translate }}"
        [options]="getActualSelectedFiles()"
      ></troi-dropdown-select>
    </div>
    <div class="files__header__page" *ngIf="!isDigitalInvoice">
      <span>{{ 'Booking.labels.page' | translate }}</span>
      <troi-icon icon="icon-arrow-head-left" (click)="previousPage()"></troi-icon>
      <troi-input-field
        [value]="page"
        [numbersOnly]="true"
        [integersOnly]="true"
        (keydown.enter)="onEnterPressed($event)"
        [height]="'25px'"
        [align]="'center'"
        (change)="onPageChange($event.target.value)"
      ></troi-input-field>
      <troi-icon icon="icon-arrow-head-right" (click)="nextPage()"></troi-icon>
    </div>
    <div class="files__header__zoom">
      <span>{{ 'Booking.labels.zoom' | translate }}</span>
      <troi-icon icon="icon-loop-with-minus" (click)="zoomOut()"></troi-icon>
      <troi-icon icon="icon-loop-with-plus" (click)="zoomIn()" style="margin-left: 10px"></troi-icon>
    </div>
  </div>
  <div class="files__uploaded" *ngIf="!isDigitalInvoice">
    <span>{{ 'Booking.labels.attachFiles' | translate }}</span>
    <troi-dropdown-select size="12px" [(ngModel)]="+year" [options]="bookingYears" data-test="year-dropdown">
    </troi-dropdown-select>
    <attach-files-dropdown
      [client]="client"
      [year]="year"
      (selected)="filesSelected($event)"
      [initValues]="initUnusedFiles"
      [preloadedOptions]="preloadedUnusedFiles"
      [reloadPreloadedOptions]="reloadPreloadedOptions"
      [enabled]="enabled"
    ></attach-files-dropdown>
  </div>
  <div class="files__content" [ngStyle]="{ height: isXml() ? '100%' : 'auto' }">
    <ngx-extended-pdf-viewer
      *ngIf="actualFile && isPdf()"
      [src]="getFileUrl()"
      [ignoreKeyboard]="false"
      [ignoreKeys]="['j', 'k', 'F4']"
      [acceptKeys]="[]"
      backgroundColor="#ffffff"
      [height]="'590px'"
      [useBrowserLocale]="true"
      [(page)]="page"
      [zoom]="zoom * 100"
      (pagesLoaded)="pdfPagesLoaded($event)"
      [showBookmarkButton]="false"
      [showDownloadButton]="false"
      [showOpenFileButton]="false"
      [showPagingButtons]="false"
      [showPresentationModeButton]="false"
      [showPrintButton]="false"
      [showPropertiesButton]="false"
      [showRotateButton]="false"
      [showScrollingButton]="false"
      [showSecondaryToolbarButton]="false"
      [showSpreadButton]="false"
      [showZoomButtons]="false"
    >
    </ngx-extended-pdf-viewer>
    <div *ngIf="actualFile && !isPdf() && !isXml()" style="max-height: 590px; overflow: auto" class="preview">
      <img class="preview__image" [style.width]="widthForImage()" [src]="getFileUrl()" />
    </div>
    <div *ngIf="actualFile && isXml()" style="height: 100%; overflow: hidden" class="preview">
      <iframe
        [src]="getXmlViewerUrl() | safe"
        (load)="onXmlViewerLoad(xmlViewer)"
        #xmlViewer
        frameborder="0"
        style="width: 100%; height: 100%"
        [ngStyle]="{ zoom: zoom }"
      ></iframe>
    </div>
  </div>
</div>
