import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { StorageKeys } from './core/storage/storage.keys';
import { StorageListener } from './core/storage/storage.listener';
import { StorageService } from './core/storage/storage.service';
import { IUser } from './core/user/IUser';
import { UserService } from './core/user/user.service';

const LOGIN_PAGE = '/app/login';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public themeColor: string;

  // current keyboard shortcut passthrough to window.top.document through troisearch:trigger event
  // this might only be temporary until we can figure out a better way to handle this
  // our current limitation here is to handle the keyboard event in the top window through the iframe
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    window.top.document.dispatchEvent(
      new CustomEvent('troisearch:trigger', { detail: { event, selection: (window.getSelection() ?? '').toString() } }),
    );
  }

  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private storageListener: StorageListener,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd && (event.url === '/menu' || event.url === '/status-manager')) {
        return;
      }

      const queryParams = this.route.snapshot.queryParams;

      if (
        queryParams['projectContext'] === 'true' ||
        queryParams['project'] ||
        queryParams['from'] === 'mis_reporting'
      ) {
        return;
      }

      window.top.document.querySelector('#appHeader')?.remove();
    });

    this.route.queryParams.subscribe((queryParams) => {
      const user: IUser = this.userService.getUserData();

      this.translate.setDefaultLang(this.getLanguage(queryParams, user));
    });
    this.themeColor = this.getThemeColorFromStorage();
    document.body.className = '';
    document.body.classList.add(this.themeColor);
    this.cdRef.detectChanges();
    this.storageListener.storageChanges.subscribe((changesData: any) => {
      switch (changesData.key) {
        case StorageKeys.THEME_COLOR:
          this.themeColor = changesData.value;
          document.body.className = '';
          document.body.classList.add(this.themeColor);
          break;
        case StorageKeys.LANG:
          this.switchLanguage(changesData.value);
          break;
      }
    });
  }

  get isLoginPage() {
    return window.location.pathname === LOGIN_PAGE;
  }

  get showMenu() {
    return !this.isLoginPage;
  }

  public switchLanguage(language: string) {
    this.translate.use(language);
  }

  private getLanguage(queryParams, user: IUser) {
    let language = environment.defaultLanguage;
    const storageLang = this.storageService.getItem(StorageKeys.LANG);

    if (user && !!user.lang.length) {
      language = user.lang;
    }

    if (storageLang) {
      language = storageLang;
    }

    return queryParams.lang ? queryParams.lang : language;
  }

  private getThemeColorFromStorage(): string {
    const color = this.storageService.getItem(StorageKeys.THEME_COLOR);

    return color || '';
  }
}
