export enum DropdownRoutesEnum {
  ACCOUNTS = '/components/common/filters/accounts',
  ACCOUNTS_CREATE_BOOKING = '/components/booking/booking-receipt-accounts',
  COST_CENTERS = '/components/common/filters/cost-centers',
  SUPPLIERS = '/components/common/filters/suppliers',
  ACCOUNT_GROUPS = '/components/common/filters/account-groups',
  PROTOCOL_STATUSES = '/components/common/filters/protocol-statuses',
  TAX_RATES = '/components/common/filters/tax-rates',
  UNITS = '/components/common/filters/units',
  CUSTOMERS = '/components/common/filters/customer',
  PROJECT_TYPES = '/components/projectlist/settings-project-types',
  PROJECTS_BY_CUSTOMER = '/components/common/dropdown/customer/projects',
  PROJECTS_BY_CLIENT = '/components/common/dropdown/projects',
  SUB_PROJECTS = '/components/common/dropdown/project/subprojects',
  CPS = '/components/booking/calculation-position/search',
  EMPLOYEES = '/components/booking/employee/search',
  EMPLOYEES_TASKPLANNING = '/components/taskplanning/helper/employee',
  PROJECT_LEADERS = '/components/projectlist/projectleader/search',
  TASK_PROJECTS = '/components/taskplanning/helper/projectDropdown',
  TASK_STATES = '/components/taskplanning/helper/statusDropdown',
  TASK_TAGS = '/components/taskplanning/helper/tagDropdown',
}
