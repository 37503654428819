import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-lumos',
  templateUrl: './menu-lumos.component.html',
  styleUrls: ['./menu-lumos.component.scss'],
})
export class MenuLumosComponent implements OnInit {
  public el: any;

  ngOnInit(): void {
    this.el = window.top.document.getElementsByTagName('twc-lumos')[0];

    // this.attachKeyboardEvents();
  }

  public toggleLumos(): void {
    this.el.inputToggle = !Boolean(this.el?.inputToggle);
  }

  // private attachKeyboardEvents(): void {
  //   window.top.document.body.addEventListener('keydown', (event) => {
  //     if (event.key === 'Escape') {
  //       this.el.inputToggle = false;
  //     }

  //     console.info('MenuLumosComponent.attachKeyboardEvents', { event });

  //     if (event.key === 'k' && (event.altKey || event.metaKey)) {
  //       this.el.inputToggle = !Boolean(this.el?.inputToggle);
  //     }
  //   });
  // }
}
