import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { DataTypeEnum } from '../../../core/enums/data-type.enum';
import { TranslationMode } from '../../../core/enums/translationMode';
import { BackendResponseDropdownDataInterface } from '../../../core/interfaces/backend.response.interface';
import { LanguagesService } from '../../../core/services/languages.service';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { AccountBookingContextInterface } from '../../bookings/common/interfaces/account-booking-context.interface';
import { Routes } from '../../project-list/enum/routes';
import { DropdownRoutesEnum } from '../enums/dropdown-routes.enum';
import { FiltersParamsInterface } from '../interfaces/filters-params.interface';
import { DropdownsNetwork } from '../networks/dropdowns.network';

@Injectable()
export class DropdownsService {
  constructor(private network: DropdownsNetwork, private languagesService: LanguagesService) {}

  public fetchObjects(
    filterObject: number,
    page: number,
    pageSize: number,
    search: string,
    lang: string,
    route: DropdownRoutesEnum | string,
    searchFieldKey = 'name',
    filterObjectKey = 'client',
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    // TODO: refactor, move to project folder scope
    if (route === Routes.DROPDOWN_PROJECT_FOLDER) {
      return this.network.fetchProjectFolders(
        route,
        filterObject,
        filterObjectKey,
        page,
        pageSize,
        search,
        searchFieldKey,
        lang,
        null,
        headers,
      );
    }

    return this.network.fetchDropdownData(
      route,
      filterObject,
      filterObjectKey,
      page,
      pageSize,
      search,
      searchFieldKey,
      lang,
      null,
      headers,
    );
  }

  public fetchProtocolStatus(
    client: number,
    page: number,
    pageSize: number,
    search: string,
    lang: string,
    year: number,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    return this.network.fetchDropdownData(
      DropdownRoutesEnum.PROTOCOL_STATUSES,
      client,
      'client',
      page,
      pageSize,
      search,
      'name',
      lang,
      year,
    );
  }

  fetchCPs(
    subproject: number,
    client: number,
    page: number,
    pageSize: number,
    search: string,
    lang: string,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    return this.network.fetchCPs(client, subproject, page, pageSize, search, lang);
  }

  fetchAccountsForCreateBooking(
    client: number,
    supplier: string,
    context: string,
    page: number,
    pageSize: number,
    name: string,
    lang: string,
  ): Observable<BackendResponseDropdownDataInterface<AccountBookingContextInterface[]>> {
    return this.network.fetchAccountsForCreateBooking(client, supplier, context, page, pageSize, name, lang);
  }

  fetchCPsByKNumber(
    page: number,
    pageSize: number,
    search: string,
    lang: string,
    route: string,
    params?: FiltersParamsInterface,
    headers?: HttpHeaders,
  ): Observable<BackendResponseDropdownDataInterface<any>> {
    return this.network.fetchCPsByKNumber(page, pageSize, search, lang, route, params, headers);
  }

  buildOptionList(
    data,
    dataType: DataTypeEnum,
    translatableName = false,
    preloadedOptions: Array<TroiDropdownListModel> = [],
    colorizeBackgroundProperty = '',
    translationMode = TranslationMode.DATA,
    multipleChoiceGroup = false,
    maxSelectionsGroup = 0,
  ): Array<TroiDropdownListModel> {
    const preloadedValues = preloadedOptions.map((option) => option.value);
    const list = [];
    const key =
      dataType === DataTypeEnum.COST_CENTERS || dataType === DataTypeEnum.ACCOUNT_GROUPS ? 'description' : 'name';

    if (dataType === DataTypeEnum.TASK_STATES && data.length > 0) {
      data = this.groupDataByClient(data);
    }

    _.forEach(data, (element) => {
      if (preloadedValues.includes(element.id)) {
        return;
      }

      let listElement = {
        label: translatableName
          ? this.languagesService.getLanguageValue(element[key], translationMode)
          : element.number
          ? `${element.number} - ${element[key]}`
          : element[key],
        value: element.id,
        active: true,
        hasCreditorAccount: element.hasCreditorAccount,
        fullObject: element,
        colorizeBackground: false,
        multipleChoice: element.multipleChoice || false,
        multipleChoiceGroup,
        maxSelections: element.maxSelections || 0,
        maxSelectionsGroup,
        isRequired: element.isRequired || false,
        group: element.children && element.children.length > 0,
        groupValues: element.children
          ? this.buildOptionList(
              element.children,
              dataType,
              translatableName,
              [],
              colorizeBackgroundProperty,
              translationMode,
              element.multipleChoice || false,
              element.maxSelections || 0,
            )
          : [],
        parent: element.parent || null,
      };

      if (colorizeBackgroundProperty !== '') {
        listElement = { ...listElement, colorizeBackground: element[colorizeBackgroundProperty] };
      }

      list.push(listElement);
    });

    return [...preloadedOptions, ...list];
  }

  /**
   * Groups the provided data by client and formats it into a specific structure.
   *
   * @param data - An array of objects containing client data.
   * @returns An array of `TypedStatus` objects, which may be formatted into a hierarchical structure based on clients.
   *
   * The function performs the following steps:
   * 1. Extracts unique clients from the provided data.
   * 2. If there is more than one unique client, it formats the data into a hierarchical structure where each client has a parent status and child statuses.
   * 3. If the data is formatted, the `isFormatted` flag is set to true.
   */
  private groupDataByClient(data: any[]): TypedStatus[] {
    const typedData = data;
    let isFormatted = false;

    const availableClients = _.uniqBy(
      typedData.map((item) => item.client),
      'id',
    );

    if (availableClients.length > 1) {
      const newData = availableClients.map((client) => {
        return {
          id: `statusParent${client.id}`,
          name: client.name,
          isRequired: false,
          maxSelections: 1,
          multipleChoice: false,
          children: typedData
            .filter((item) => item.client.id === client.id)
            .map((item) => {
              return {
                id: item.id,
                name: item.name,
                parent: `statusParent${item.client.id}`,
              };
            }),
        };
      });

      data = newData;
      isFormatted = true;
    }

    return data;
  }
}

interface TypedStatus {
  id: number;
  name: string;
  children: TypedStatusChildren[];
}

interface TypedStatusChildren {
  id: number;
  name: string;
  client: {
    id: number;
    name: string;
  };
}
