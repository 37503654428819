import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Routes } from '../enums/routes';
import { BackendResponseInterface } from '../interfaces/backend.response.interface';
import type { EnvVariablesInterface } from '../interfaces/settings/env-variables.interface';
import { SettingsResponseInterface } from '../interfaces/settings/settings-response.interface';

@Injectable()
export class BasicSettingsNetwork {
  private route: string;

  private maxCacheAge = 5000;

  public constructor(public http: HttpClient) {}

  public setRoute(route: string): void {
    this.route = route;
  }

  private _lastSettingsRequest = 0;
  private _lastSettingsDownload$: Observable<BackendResponseInterface<SettingsResponseInterface>>;
  private _lastSettingsDownloadParams: string;

  public downloadSettings(clientId: number, withObjects = 'true') {
    if (typeof clientId === 'string') {
      clientId = parseInt(clientId, 10);
    }

    const params = JSON.stringify([clientId, withObjects]);
    const now = Date.now();

    if (
      this._lastSettingsRequest + this.maxCacheAge < now ||
      this._lastSettingsDownloadParams !== params ||
      !this._lastSettingsDownload$
    ) {
      this._lastSettingsRequest = now;
      this._lastSettingsDownloadParams = params;
      this._lastSettingsDownload$ = this.requestDownloadSettings(clientId, withObjects).pipe(shareReplay(1));
    }

    return this._lastSettingsDownload$;
  }

  private requestDownloadSettings(
    clientId: number,
    withObjects = 'true',
  ): Observable<BackendResponseInterface<SettingsResponseInterface>> {
    const params = new HttpParams({
      fromObject: {
        client: clientId ? clientId.toString() : '',
        withObjects,
      },
    });
    return this.http.get<BackendResponseInterface<SettingsResponseInterface>>(`${environment.url}${this.route}`, {
      params,
    });
  }

  public getEnvVariables(): Observable<EnvVariablesInterface> {
    return this.http.get<EnvVariablesInterface>(`${environment.url}${Routes.ENV_VARIABLES}`);
  }
}
