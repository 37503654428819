import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeskSettingsService } from '../../modules/desk/services/desk-settings.service';
import { TimeRecordingService } from '../../modules/time-recording/time-recording.service';

@Component({
  selector: 'troi-project-tooltip',
  templateUrl: './troi-project-tooltip.component.html',
  styleUrls: ['./troi-project-tooltip.component.scss'],
})
export class TroiProjectTooltipComponent implements OnInit {
  @Input() placement = 'top bottom';
  @Input() projectItem;
  @Input() type = '';
  @Output() openStateChanged = new EventEmitter<boolean>();
  @Output() loggedWork = new EventEmitter<string>();
  cutShowText = '-';
  showText = '-';
  public titleShow = true;
  public showPopup = false;

  @Input()
  public set cutLength(value: number | string) {
    this._cutLength = coerceNumberProperty(value);
  }

  public get cutLength(): number {
    return this._cutLength;
  }

  private _cutLength = 50;

  constructor(public deskSettingsService: DeskSettingsService, public timeService: TimeRecordingService) {}

  ngOnInit(): void {
    let originalText = '';

    if (this.type === 'billingInternalComment') {
      originalText = this.projectItem.billing.billingInternalComment;
      this.showText = this.projectItem.billing.billingInternalComment;
    }

    if (this.type === 'billingExternalComment') {
      originalText = this.projectItem.billing.billingExternalComment;
      this.showText = this.projectItem.billing.billingExternalComment;
    }

    this.cutShowText = originalText.slice(0, this.cutLength);
    if (originalText.length > this.cutLength) {
      this.showPopup = true;
      this.cutShowText += '...';
    }
  }

  public toggleOverlay(popover): void {
    if (popover.isOpen()) {
      this.closeOverlay(popover);
    } else {
      this.openOverlay(popover);
    }
  }

  openOverlay(popover): void {
    if (!this.showPopup) {
      return;
    }

    if (popover.isOpen()) {
      return;
    }
    this.titleShow = false;
    popover.open();
    this.openStateChanged.emit(true);
  }

  closeOverlay(popover): void {
    this.titleShow = true;
    if (!popover.isOpen()) {
      return;
    }
    popover.close();
    this.resetAndReload();
  }

  onCancel(popover): void {
    this.closeOverlay(popover);
  }

  onHidden(): void {
    this.resetAndReload();
  }

  resetAndReload() {}
}
