<div class="project-list" #optionWrapper (scroll)="calculateScrollPosition()">
  <div #optionList (scroll)="calculateScrollPosition()">
    <ng-container *ngFor="let subProject of getSubprojects()">
      <div
        class="project-list__elem"
        *ngIf="!subProject.deleted && !subProject.isBlockedForBooking"
        [ngClass]="{
          'project-list__elem--clickable': subProject.leaves.length === 0
        }"
      >
        <span (click)="onLeafSelected(subProject, subProject.leaves.length === 0)">{{
          languagesService.getLanguageValue(subProject.name)
        }}</span>
        <div
          class="project-list__elem__leaf"
          *ngFor="let leaf of subProject.leaves"
          [title]="languagesService.getLanguageValue(leaf.path)"
        >
          <span class="project-list__elem--clickable" (click)="onLeafSelected(leaf, true)">{{
            languagesService.getLanguageValue(leaf.name)
          }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
